<template>
    <b-modal ref="modal-register-v3" id="modal-signup-v3" hide-header hide-footer>
        <p class="title-form-v3">Sign Up</p>
        <p class="sub-title-form-v3">Already have an account? <span @click="showModalSignIn()" class="blue-text-v3 cursor-pointer">Sign In</span></p>
        <b-form action="" @submit.prevent="tryToRegister">
            <div class="form-input">
            <b-form-group>
            <!-- <b-form-group :invalid-feedback="invalidFirstName" :state="stateFirstName"> -->
                <b-form-input
                id="first-name-register-v3"
                v-model.trim="$v.valueFirstName.$model"
                type="text"
                class="form-control input-box-text-v3"
                :class="{'is-invalid': validationStatus($v.valueFirstName)}"
                placeholder="First name"></b-form-input>
                <div class="invalid-feedback">
                    <span v-if="!$v.valueFirstName.minLength" id="first-name-min-error" class="required-color error-message-v3">First name must be at least 3 characters.</span>
                    <span v-if="!$v.valueFirstName.maxLength" id="first-name-error" class="required-color error-message-v3">First name max. length is 100 characters.</span>
                    <span v-if="!$v.valueFirstName.required" id="first-name-empty" class="required-color error-message-v3">First name cannot be empty.</span>
                </div>
            </b-form-group>
            </div>
            <div class="form-input">
            <b-form-group>
                <b-form-input
                v-model.trim="$v.valueLastName.$model"
                type="text"
                class="form-control input-box-text-v3"
                :class="{'is-invalid': validationStatus($v.valueLastName)}"
                placeholder="Last name"></b-form-input>
                <div class="invalid-feedback">
                    <span v-if="!$v.valueLastName.mimLength" id="last-name-min-error" class="required-color error-message-v3">Last name must be at least 3 characters.</span>
                    <span v-if="!$v.valueLastName.maxLength" id="last-name-error" class="required-color error-message-v3">Last name max. length is 100 characters.</span>
                    <span v-if="!$v.valueLastName.required" id="last-name-empty" class="required-color error-message-v3">Last name cannot be empty.</span>
                </div>
            </b-form-group>
            </div>
            <div class="form-input">
            <b-form-group :invalid-feedback="invalidEmail">
                <b-form-input
                :state="stateEmail"
                v-model.trim="$v.email.$model"
                type="email"
                class="form-control input-box-text-v3"
                :class="{'is-invalid': validationStatus($v.email)}"
                placeholder="Email"></b-form-input>
                <div class="invalid-feedback">
                    <span v-if="!$v.email.email" id="email-error" class="required-color error-message-v3">Please enter a valid email (e.g., example@mail.com).</span>
                    <span v-if="!$v.email.required" id="email-empty" class="required-color error-message-v3">Email cannot be empty.</span>
                    <!-- <span id="email-already-registered" class="required-color error-message-v3">This email is already registered. Sign in with this email or reset your password.</span> -->
                </div>
            </b-form-group>
            </div>
            <div class="form-input">
            <b-form-group>
                <b-form-input
                id="password-register-v3"
                v-on:keyup="validatePassword()"
                v-model.trim="$v.password.$model"
                :type="passwordState"
                class="form-control input-box-v3"
                :class="{'is-invalid': validationStatus($v.password)}"
                placeholder="Password"></b-form-input>
                <div class="invalid-feedback">
                    <span v-if="!$v.password.valid" id="password-error" class="required-color error-message-v3"></span>
                    <span v-if="!$v.password.required" id="password-empty" class="required-color error-message-v3">Password cannot be empty.</span>
                    <span v-if="!$v.password.maxLength" id="password-max-length" class="required-color error-message-v3">Password max. length is 128 characters.</span>
                </div>
                <img v-if="passwordState == 'password'" src="@/assets/images/icon/fi-rr-eye-close.svg" alt="" @click="showPassword()">
                <img v-else src="@/assets/images/icon/fi-rr-eye-open.svg" alt="" @click="showPassword()">
                <div class="row">
                <div class="col-6 required-password">
                    <ul>
                    <li class="marker-grey" id="lower-char">One lowercase letter</li>
                    <li class="marker-grey" id="upper-char">One uppercase letter</li>
                    <li class="marker-grey" id="number-char">One number</li>
                    </ul>
                </div>
                <div class="col-6 required-password">
                    <ul>
                    <li class="marker-grey" id="special-char">One special character</li>
                    <li class="marker-grey" id="minimum-character">8 characters minimum</li>
                    </ul>
                </div>
                </div>
            </b-form-group>
            </div>
            <b-form-checkbox
            id="checkbox-signup"
            class="checkbox checkbox-register-v3"
            v-model="termCondition"
            name="checkbox-signup"
            value="accepted"
            unchecked-value="not_accepted"
            >
            <p class="sub-title-form-v3 margin-top-check-v3">I have read and agree to the Jaybod <span><router-link tag="a" to="" class="blue-text-v3" href="https://jaybod.com/terms-service" target="_blank">Terms</router-link></span> & <span><router-link tag="a" to="" class="blue-text-v3" href="https://jaybod.com/terms-service" target="_blank">Privacy Policy</router-link></span>.</p>
            </b-form-checkbox>
            <div class="d-flex justify-content-between align-items-center">
            <button :disabled="$v.$invalid" :class="!$v.$invalid ? 'active btn base-btn-v3' : 'btn base-btn-v3'">
                <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i> 
                <span class="default-text-style"><span :class="!$v.$invalid ? 'text-white' : ''">Sign Up</span></span>
            </button>
            </div>
        </b-form>
    </b-modal>
</template>

<script>
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      valueFirstName: '',
      valueLastName: '',
      email: '',
      password: '',
      passwordState: 'password',
      loading: false,
      tryingToRegister: false,
      termCondition: false,
      invalidFirstName: "Your account or First Name is incorrect.",
      stateFirstName: true,
      invalidLastName: "Your account or Last Name is incorrect.",
      stateLastName: true,
      invalidEmail: "Your account or Email is incorrect.",
      stateEmail: true,
      invalidPassword: "Your account or password is incorrect.",
      statePassword: true,
      authError: null,
      isAuthError: false,
    }
  },
  validations: {
    valueFirstName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(100)
    },
    valueLastName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(100)
    },
    email: {
      required,
      email,
      isUnique (value) {
        if (value === '') return true
        var email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(email_regex.test(value))
          }, 350 + Math.random()*300)
        })
      }
    },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(128),
      valid: function(value) {
        const containsUppercase = /[A-Z]/.test(value)
        const containsLowercase = /[a-z]/.test(value)
        const containsNumber = /[0-9]/.test(value)
        const containsSpecial = /[!#@~*&^%)([\]\\+=?<>;:"`$_)-/',.|}{]+/.test(value)
        return containsUppercase && containsLowercase && containsNumber && containsSpecial
      }
    }
  },
  methods: {
    ...authMethods,
    showModalSignIn() {
        this.$bvModal.hide('modal-signup-v3')
        this.$bvModal.show('modal-signin-v3')
    },
    validationStatus(validation) {
      return typeof validation != undefined ? validation.$error: false
    },
    showPassword() {
      if(this.passwordState === 'password'){
        this.passwordState = 'text'
      } else {
        this.passwordState = 'password'
      }
    },
    validatePassword() {
      var lower = document.getElementById("lower-char")
      var upper = document.getElementById("upper-char")
      var num = document.getElementById("number-char")
      var special = document.getElementById("special-char")
      var minChar = document.getElementById("minimum-character")

      if(this.password.match(/[a-z]/)){
        lower.style.color = '#262A2C'
        lower.style.setProperty('--before-char', '#009934')
      } else {
        lower.style.color = '#919394'
        lower.style.setProperty('--before-char', '#E9EAEA')
      }

      if(this.password.match(/[A-Z]/)){
        upper.style.color = '#262A2C'
        upper.style.setProperty('--before-char', '#009934')
      } else {
        upper.style.color = '#919394'
        upper.style.setProperty('--before-char', '#E9EAEA')
      }

      if(this.password.match(/[0-9]/)){
        num.style.color = '#262A2C'
        num.style.setProperty('--before-char', '#009934')
      } else {
        num.style.color = '#919394'
        num.style.setProperty('--before-char', '#E9EAEA')
      }

      if(this.password.match(/[!#@~*&^%)([\]\\+=?<>;:"`$_)-/',.|}{]+/)){
        special.style.color = '#262A2C'
        special.style.setProperty('--before-char', '#009934')
      } else {
        special.style.color = '#919394'
        special.style.setProperty('--before-char', '#E9EAEA')
      }

      if(this.password.length >= 8){
        minChar.style.color = '#262A2C'
        minChar.style.setProperty('--before-character', '#009934')
      } else {
        minChar.style.color = '#919394'
        minChar.style.setProperty('--before-character', '#E9EAEA')
      }
    },
    tryToRegister() {
      this.$ga.event('register-btn-V3', 'actionV2x', 'labelV2x', 1)
      this.loading = true;
      this.tryingToRegister = true;
      // Reset the authError if it existed.
      this.authError = null;
      return (
        this.register({
          firstname: this.valueFirstName,
          lastname : this.valueLastName,
          email: this.email,
          password: this.password,
          confirm_password : this.password
        })
          // eslint-disable-next-line no-unused-vars
          .then(token => {
            this.tryingToRegister = false;
            this.isAuthError = false;
            this.loading = false;
            // Redirect to the originally requested page, or to the home page
            // this.$router.push(
            //   this.$route.query.redirectFrom || { name: "Verify" }
            // );
            sessionStorage.setItem('emailUser', this.email)
            // this.$refs['modal-register-v3'].hide()
            // this.$root.$emit('bv::show::modal', 'modal-verify-code', '#modal-verify')
            this.$bvModal.hide('modal-signup-v3')
            this.$bvModal.show('modal-verify-code')
          })
          .catch(error => {
            this.tryingToRegister = false;
            this.stateEmail = false
            this.invalidEmail = error ? error.data.display_message : "";
            this.authError = error ? error : "";
            this.isAuthError = true;
            this.loading = false;
          })
      );
    }
  },
  mounted() {
    this.$ga.event('register-page-v3', 'actionx', 'labelx', 1)
  }
}
</script>

<style scoped>
input[type="checkbox"]{
    border: 3px solid #000000;
}
.is-invalid{
    border: 1px solid #D42020 !important;
    background: #FFFFFF;
    border-radius: 4px;
    background-image: none !important;
    padding-right: 16px !important;
}
.is-valid{
    border: 1px solid #ced4da;
    background: #FFFFFF;
    border-radius: 4px;
    background-image: none !important;
    padding-right: 16px !important;
}
.is-valid:focus{
    border: 1px solid #ced4da;
    box-shadow: none;
}
</style>
