<template>
  <b-modal ref="modal-verify" id="modal-verify-code" no-close-on-backdrop hide-header hide-footer>
    <h1 class="text-center h1-title ">Confirm Your Email</h1>
    <p class="default-text-style">Enter the code received via email, or click on the link, <b>to confirm your account.</b></p>
    <div class="card-body pt-4">
        <b-alert v-model="isAuthError" variant="success" class="mt-3" dismissible>{{authError}}</b-alert>
        <b-form class="p-2" @submit.prevent="tryToLogIn">
        <b-form-group id="input-group-1" class="py-2" :invalid-feedback="invalidEmail" :state="stateEmail"   label-class="label-input" label-for="input-1">
            <b-form-input id="input-1" class="login-input-style" v-model="email" type="text" placeholder="Verification code"></b-form-input>
        </b-form-group>
        <div class="mt-2 pt-2 d-flex justify-content-between align-items-center">
            <b-button @click="tryToResend" class="default-text-style btn-resend"><span class="text-blue">Send again</span></b-button>
            <button class="btn base-btn bg-grey">
                <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                <span class="default-text-style">Verify</span>
            </button>
        </div>
        </b-form>
    </div>
  </b-modal>
</template>

<script>
import { authMethods } from "@/state/helpers";

export default {
  name: 'ModalVerifyCode',
  data() {
    return {
      email: "",
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      invalidEmail : "Your account or Email is incorrect.",
      stateEmail : true,
      loading : false,
      landing : process.env.VUE_APP_LANDING
    };
  },
  methods: {
    ...authMethods,
    landingPage(){
      window.open(this.landing,"_self")
    },
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.tryingToLogIn = true;
      // Reset the authError if it existed.
      this.authError = null;
      this.loading = true;
      return (
        this.verify({
          vcode: this.email,
        })
          // eslint-disable-next-line no-unused-vars
          .then(token => {
            this.tryingToLogIn = false;
            this.isAuthError = false;
            this.stateEmail = true;
            this.loading = false;
            // Redirect to the originally requested page, or to the home page
            // this.$refs['modal-verify'].hide()
            this.$root.$emit('bv::hide::modal', 'modal-verify-code')
            this.$root.$emit('bv::show::modal', 'modal-confirmed-account')
          })
          .catch(error => {
            this.tryingToLogIn = false;
            // this.authError = error ? error : "";
            this.invalidEmail = error ? error : "";
            this.stateEmail = false;
            this.loading = false;
            // this.isAuthError = true;
          })
      );
    },
    tryToResend() {
      this.loading = true;
      this.tryingToLogIn = true;
      // Reset the authError if it existed.
      this.authError = null;
      this.isAuthError = false;
      return (
        this.resendCode({
          email: sessionStorage.getItem('emailUser')
        })
          // eslint-disable-next-line no-unused-vars
          .then(token => {
            this.tryingToLogIn = false;
            this.isAuthError = true;
            this.authError = token;
            this.stateEmail = true;
            this.loading = false;
            
          })
          .catch(error => {
            this.loading = false;
            this.tryingToLogIn = false;
            this.invalidEmail = error ? error : "";
            this.stateEmail = false;
            this.isAuthError = false;
          })
      );
    }
  }
}
</script>

<style scoped>
.btn-resend{
  border: 0;
  background: white;
}
</style>