<template>
    <b-modal ref="modal-login-v3" id="modal-signin-v3" centered hide-footer hide-header>
        <p class="title-form-v3">Sign In</p>
        <p class="sub-title-form-v3">Don’t have an account? <span class="blue-text-v3 cursor-pointer" @click="showModalSignUp()">Sign Up</span></p>
        <b-form action="" @submit.prevent="tryToLogIn">
            <div class="form-input">
            <b-form-group :invalid-feedback="invalidEmail" :state="stateEmail">
                <b-form-input
                v-model="email"
                type="email"
                class="form-control input-box-text-v3"
                placeholder="Email"></b-form-input>
            </b-form-group>
            </div>
            <div class="form-input">
            <b-form-group :invalid-feedback="invalidPassword" :state="statePassword">
                <b-form-input
                v-model="password"
                :type="passwordState"
                class="form-control input-box-v3"
                placeholder="Password"></b-form-input>
                <img v-if="passwordState == 'password'" src="@/assets/images/icon/fi-rr-eye-close.svg" alt="" @click="showPassword()">
                <img v-else src="@/assets/images/icon/fi-rr-eye-open.svg" alt="" @click="showPassword()">
            </b-form-group>
            </div>
            <b-form-checkbox
            id="checkbox-signin"
            name="checkbox-signin"
            value="accepted"
            unchecked-value="not_accepted"
            >
            <p class="sub-title-form-v3 margin-top-check-v3">Remember me</p>
            </b-form-checkbox>
            <div class="d-flex justify-content-between align-items-center">
            <router-link tag="a" to="/forgot-password" class="sub-title-form-v3"><span class="blue-text-v3">Forgot Password</span></router-link>
            <button :class="loading || !email.length || !password.length ? 'btn base-btn' : 'active btn base-btn'" :disabled="loading || !email.length || !password.length">
                <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i> 
                <span class="sub-title-form-v3"><span :class="loading || !email.length || !password.length ? '' : 'text-white'">Sign In</span></span>
            </button>
            </div>
        </b-form>
    </b-modal>
</template>

<script>
import { authMethods } from "@/state/helpers"

export default {
  name: 'ModalLogin',
  data () {
    return {
      email: '',
      password: '',
      passwordState: 'password',
      loading: false,
      tryingToLogIn: false,
      invalidEmail: "Your account or Email is incorrect.",
      stateEmail: true,
      invalidPassword: "Your account or password is incorrect.",
      statePassword: true,
      authError: null,
      isAuthError: false,
    }
  },
  methods: {
    ...authMethods,
    showModalSignUp() {
        this.$bvModal.show('modal-signup-v3')
        this.$bvModal.hide('modal-signin-v3')
    },
    showPassword() {
      if(this.passwordState === 'password'){
        this.passwordState = 'text'
      } else {
        this.passwordState = 'password'
      }
    },
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.$ga.event('send', 'login', 'action', 'label', 1)
      this.$ga.event('loginx', 'actionx', 'labelx', 1)
      this.tryingToLogIn = true;
      this.loading = true;
      if(this.email == ""){
        this.invalidEmail = "Please Fill Email Field.";
        this.stateEmail = false;
        this.loading = false;
        return false;
      }else{
        this.stateEmail=true;
      }
      if(this.password == ""){
        this.invalidPassword = "Please Fill Password Field."
        this.statePassword = false;
        this.loading = false;
        return false;
      }else{
        this.statePassword = true;
      }
      // Reset the authError if it existed.
      this.authError = null;
      return (
        this.logIn({
          email: this.email,
          password: this.password
        })
          // eslint-disable-next-line no-unused-vars
          .then(token => {
            this.tryingToLogIn = false;
            this.isAuthError = false;
            this.loading = false;
            // Redirect to the originally requested page, or to the home page
            this.$store.commit('state/SET_STATE', 'storage')
            this.$store.commit("workgroup/SET_WORKGROUP",null)
            this.$store.commit("storage/DELETE_BREADCUMB",[]);
            this.$store.dispatch("storage/listing")
            this.$router.push(
              this.$route.query.redirectFrom || { name: "home" }
            ).catch(()=>{});
            this.$refs['modal-login-v3'].hide()
          })
          .catch(error => {
            this.invalidEmail = "";
            this.stateEmail = false;
            this.tryingToLogIn = false;
            this.invalidPassword = error ? error : "";
            this.statePassword = false;
            this.authError = error ? error : "";
            this.isAuthError = true;
            this.loading = false;
          })
      );
    }
  }
}
</script>

<style scoped>
.register-btn-modal{
    border: #dddddd !important;
    padding: 0px;
    color: #00AAFF !important;
    background: white;
    margin: 0px 0px 0px !important;
}
</style>