<template>
  <b-modal no-close-on-backdrop ref="modal-confirmed" id="modal-confirmed-account" hide-header hide-footer>
    <h1 class="text-center h1-title ">Account Confirmed</h1>
    <p class="text-center px-4 font-size-16">We’re thrilled to have you on board!</p>
    <b-alert variant="success" class="mt-3 px-4 mx-4 minW" show>You’re now registered and sign in!</b-alert>
    <br>
    <div class="justify-content-center d-flex">
        <button @click="$bvModal.hide('modal-confirmed-account')" class="btn btn-confirmed">OK</button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalConfirmed',
}
</script>

<style>
.btn-confirmed{
    width: 376px;
    background: #00AAFF;
    height: 50px;
    color: white;
}
</style>